<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left">
      </div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons"/>
      </div>
      <div class="align-right">
      </div>
    </div>
    <div style="display: flex; padding: 5px; margin-bottom:25px;"> <!-- border-bottom:1px solid #ccc; -->
      <div style="width: 33%; font-size: 32px; text-align: left; color: #e51550; font-weight:400; ">Änderungen seit letztem Import</div>
    </div>


    <div v-if="loading">
      <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br/>
      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>


    <div v-if="!loading">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Mitarbeiter</th>
            <th scope="col">letzter Import</th>
            <th scope="col">Details</th>
            <th scope="col">Aktionen</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="emp in changedEmployees" :key="'changedEmp'+emp.emp.id" style="line-height: 36px">
          <td style="width: 20%">{{ emp.emp.lastName + " " + emp.emp.firstName }}</td>
          <td v-if="emp.candidate && emp.candidate.jobEnded" style="width: 20%">{{ $helpers.convertUTCDateStringTZ(emp.candidate.jobEnded, 'DD.MM.YYYY HH:mm:ss') }}</td>
          <td v-else style="width: 20%; color: var(--ml-alt)">kein Import vorhanden</td>
          <td style="width: 20%">
            <button type="button" class="btn btn-sm btn-ml" style="margin-left: 1%"
                    v-on:click="openDataChangedModal(emp)">Details</button>
          </td>
          <td style="width: 20%">
<!--            <button v-if="emp.candidate && emp.candidate.jobEnded"-->
<!--                    type="button" class="btn btn-sm btn-ml" style="margin-left: 1%" v-on:click="forceEmployeeUpdateFromCandidate(emp.candidate, emp.emp)"-->
<!--                    v-bind:class="{ disabled: loadingDownload || loadingExport }">-->
<!--              <i v-tooltip="'Änderungen zurücksetzen'" class="fas fa-rotate-left" />-->
<!--            </button>-->
            <button v-if="employeeSyncSetting"
                    type="button" class="btn btn-sm btn-ml" style="margin-left: 1%" v-on:click="downloadCSV([emp.emp.id])"
                    v-bind:class="{ disabled: loadingDownload || loadingExport }">
              <i v-tooltip="'Änderungen Export herunterladen'" class="fas fa-file-download" style="width: 14px" />
            </button>
            <button v-if="employeeSyncSetting && employeeSyncSetting.enableExportViaTransferService && employeeSyncSettingsData.enableExportViaTransferService"
                    type="button" class="btn btn-sm btn-ml" style="margin-left: 1%" v-on:click="triggerCsvExport([emp.emp.id])"
                    v-bind:class="{ disabled: loadingDownload || loadingExport }">
              <i v-tooltip="'Änderungen exportieren'" class="fas fa-file-export" />
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <b-modal size="lg" ref="modal-data-changed" @close="closeDataChangedModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">
            Datenänderung in Meine Lohnabrechnung!
          </div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <div class="description">
            Seit dem letzten Import folgender MitarbeiterIn haben sich Daten in ML geändert. <br/>
            Hier können Sie die Änderungen überprüfen und exportieren. <br/> <br/>
          </div>
          <div>
            Zu prüfende MitarbeiterIn:
          </div>
          <div class="emp-core-data">
            <div v-if="profilePictureUrl != null">
              <img
                style="margin: 4px; width: 120px; height: 120px"
                crossorigin="anonymous"
                id="employee-picture-img"
                :src="profilePictureUrl"
              />
            </div>
            <div>
              Name: <b>{{ externalImportEmployee.firstName + " " + externalImportEmployee.lastName }}</b>
              <br>
              Geburtsdatum: <b>{{ formatDate(externalImportEmployee.dob) }}</b>
              <br>
              Sozialversicherungsnummer: <b>{{ externalImportEmployee.socialsecurity }}</b>
            </div>
          </div>
          <div>
            <br>Veränderte Daten:
          </div>
          <table style="width: 100%">
            <tr>
              <th>Feld</th>
              <th>Zuletzt importierte Daten</th>
              <th>Datenänderung in ML</th>
            </tr>
            <tr v-for="field in externalImportIssueFields">
              <th>{{ field.field }}</th>
              <td>{{ field.candidate }}</td>
              <td>{{ field.employee }}</td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="buttons">
<!--          <div class="button ml" v-bind:class="{ disabled: loadingDownload || loadingExport }" @click="closeDataChangedModal()">-->
<!--            <div class="label">Änderungen zurücksetzen</div>-->
<!--          </div>-->
          <div v-if="employeeSyncSetting && employeeSyncSetting.enableExportViaTransferService && employeeSyncSettingsData.enableExportViaTransferService
                    && !(employeeSyncSetting.enablePeriodicExport && employeeSyncSettingsData.enablePeriodicExport)"
               class="button ml" v-bind:class="{ disabled: loadingExport }" @click="triggerCsvExport([externalImportEmployee.id])">
            <div class="label">Änderungen exportieren</div>
          </div>
          <div v-if="employeeSyncSetting && !(employeeSyncSetting.enableExportViaTransferService && employeeSyncSettingsData.enableExportViaTransferService)"
               class="button ml" v-bind:class="{ disabled: loadingDownload }" @click="downloadCSV([externalImportEmployee.id])">
            <div class="label">Änderungen Export herunterladen</div>
          </div>
          <div class="button" v-bind:class="{ disabled: loadingDownload || loadingExport }" @click="closeDataChangedModal()">
            <div class="label">Schließen </div>
          </div>
        </div>

      </template>
    </b-modal>

  </div>

</template>

<script>
import VueEventBus from '../../vue-event-bus';
import {mapState, mapGetters} from 'vuex';
import Papa from "papaparse";

export default {
  components: {},
  name: 'employeeExportOverview',
  data() {
    return {
      loading: true,

      importJob: {
        id: null,
        method: null
      },
      externalImportCandidate: {},
      externalImportContract: {},
      externalImportEmployee: {},
      externalImportIssueFields: [],
      profilePictureUrl: null,

      candidates: [],
      importCandidates: [],

      employeeCandidateDiffId: null,

      inspectedCandidate: {},
      inspectedCandidates: [],

      jobs: [],
      filteredJobs: [],
      jobsPerPage: 10,
      currentPage: 1,

      employees: [],
      employeeContracts: [],
      allowedContracts: [],

      authusers: {},
      filterDropdownOptions: [],

      employeeCategoryArray: [],
      employmentTypeArray: [],
      contractTypeArray: [],
      wageTypeArray: [],
      countryArray: [],
      genderArray: [],
      genderCandidateArray: {
        "M": 1,
        "F": 2,
        "D": 3
      },
      citizenTypeArray: [],
      civilStatusArray: [],

      employeeSyncSetting: undefined,
      employeeSyncSettingsData: {
        enableExportViaTransferService: false,
        enablePeriodicExport: false,
      },

      loadingDownload: false,
      loadingExport: false,

      financialAccountingGroups: [],

      companyData: null,
      enableImport: false,
      softwareIds: {
        0: "BMD",
        1: "DPW",
        2: "DATEV über SOTEC",
      },
    }
  },
  created: function () {
    //console.log("CREATED");
  },
  beforeDestroy: function () {
  },
  mounted: function () {
    let view = this;
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
    let url = "/enum/country";
    this.api.get(url).then((response) => {
      for (let x in response.data) {
        let elem = response.data[x];
        view.countryArray[elem.id] = elem.valueGER;
      }
    });

    url = "/enum/gender";
    this.api.get(url).then((response) => {
      for (let x in response.data) {
        let elem = response.data[x];
        view.genderArray[elem.id] = elem.valueGER;
      }
    });

    url = "/enum/citizentype";
    this.api.get(url).then((response) => {
      for (let x in response.data) {
        let elem = response.data[x];
        view.citizenTypeArray[elem.id] = elem.valueGER;
      }
    });

    url = "/enum/civilstatus";
    this.api.get(url).then((response) => {
      for (let x in response.data) {
        let elem = response.data[x];
        view.civilStatusArray[elem.id] = elem.valueGER;
      }
    });
  },
  computed: {
    ...mapGetters(['companyId', 'isSuperUser', 'uiDataMap']),
    headerButtons() {
      let view = this;
      let state = {
        buttons: [
          // BUTTONS WHILE NO JOB IS SELECTED
          {
            type: "block border-r",
            icon: "fa-refresh",
            show: view.importJob.id == null,
            click: () => {
              view.refresh();
            },
            tooltip: 'Daten neu laden'
          },
          {
            type: view.employeeSyncSettingsData.enableExportViaTransferService ? "block" : "block border-r",
            icon: "fa-file-download",
            show: view.employeeSyncSetting && !view.loadingDownload && !view.saves && !view.editieren,
            disabled: view.loadingExport,
            click: () => {
              view.downloadCSV(view.changedEmployeeIds);
            },
            tooltip: "Download Export für alle Mitarbeiter mit Änderungen",
            title: "Download Export für alle Mitarbeiter mit Änderungen",
          },
          {
            show: view.loadingDownload,
            type: view.employeeSyncSettingsData.enableExportViaTransferService ? "block" : "block border-r",
            icon: "fa-spinner fa-spin",
            click: () => {
            },
            tooltip: "Download wird generiert",
          },
          {
            type: "block border-r",
            icon: "fa-file-export",
            show: view.employeeSyncSetting && view.employeeSyncSettingsData.enableExportViaTransferService && !view.loadingExport && !view.saves && !view.editieren,
            disabled: view.loadingDownload,
            click: () => {
              view.triggerCsvExport(view.changedEmployeeIds);
            },
            tooltip: "Export zur Lohnverrechnung für alle Mitarbeiter mit Änderungen",
            title: "Export zur Lohnverrechnung für alle Mitarbeiter mit Änderungen",
          },
          {
            show: view.loadingExport,
            type: "block border-r",
            icon: "fa-spinner fa-spin",
            click: () => {
            },
            tooltip: "Export wird generiert",
          },
        ],
        helperLinks: [
          {
            title: "Import Jobs",
            helperText:
                "Auf dieser Seite können Sie Mitarbeiter aus verschiedenen System importieren. Für eine detailierte Beschreibung, klicken Sie auf 'Mehr erfahren'.",
            externalUrl:
                "https://meindienstplan.atlassian.net/servicedesk/customer/kb/view/2640084993",
            externalLabel: "Mehr erfahren",
            cancelLabel: "Danke",
            icon: "fa-rotate"
          },
          {
            title: "Support erhalten",
            helperText:
                "Wenn Sie auf Ihre Frage keine Antwort im Hilfecenter finden, können Sie uns eine Support Anfrage stellen. Klicken Sie dazu einfach auf 'Support kontaktieren', geben Sie Ihre E-Mail Adresse ein, schreiben Sie eine detaillierte Zusammenfassung Ihres Problems inkl. der Daten Ihres Stores, laden Sie etwaige Anhänge (Bilder, etc.) hoch und klicken Sie auf 'Senden'. Wir sehen uns Ihr Problem an und melden uns so schnell wie möglich.",
            externalUrl:
                "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/group/11",
            externalLabel: "Support kontaktieren",
            cancelLabel: "Schließen",
          }
        ]
      };
      return state;
    },
    software() {
      let view = this;
      if (view.companyData != null) {
        return view.softwareIds[view.companyData.softwareId];
      } else {
        return "Lohnverrechnungstool";
      }
    },
    fibuByNumber() {
      let fibuByNumber = {};
      Object.values(this.financialAccountingGroups).forEach(fibu => {
        fibuByNumber[fibu.number] = fibu;
      });
      return fibuByNumber;
    },
    changedEmployees() {
      let view = this;

      let changedEmps = [];
      for (let i = 0; i < view.employees.length; i++) {
        let emp = view.employees[i];
        emp.active = false;
        emp.contract = null;
        emp.contracts = view.employeeContracts[emp.id];

        // skip external emps if setting is active
        if (view.employeeSyncSetting && view.employeeSyncSetting.ignoreExternalEmployees
            && view.employeeSyncSettingsData && view.employeeSyncSettingsData.ignoreExternalEmployees && emp.external) {
          continue;
        }
        // skip hidden emps if setting is active
        if (view.employeeSyncSetting && view.employeeSyncSetting.ignoreHiddenEmployees &&
            view.employeeSyncSettingsData && view.employeeSyncSettingsData.ignoreHiddenEmployees && emp.isHidden) {
          continue;
        }

        let candidate = undefined;
        // candidates are ordered by desc created_at --> get first entry in list
        view.candidates.every(c => {
          if (c.remoteId === emp.id // match with either remoteId
              || (c.externalId !== null
                  && ((view.softwareIds[view.companyData.softwareId] === "BMD" && emp.bmdId !== null && c.externalId.toString() === emp.bmdId.toString())) // or bmdId
                      || ((view.softwareIds[view.companyData.softwareId] === "DPW" && emp.dpwId !== null && c.externalId.toString() === emp.dpwId.toString())))) { // or dpwId
            candidate = c;
            return false;
          }
          return true;
        });

        // check contracts
        if (emp.contracts) {
          for (let j = 0; j < emp.contracts.length; j++) {
            if (emp.contracts[j]) {
              if (!emp.contract) {
                emp.contract = emp.contracts[j];
              }
              if (view.$helpers.getMomentFromStringTZ(emp.contracts[j].from).isAfter(view.$helpers.getMomentFromStringTZ(emp.contract.from))) {
                emp.contract = emp.contracts[j];
              }
              if (!emp.contract.to || view.$helpers.getMomentFromStringTZ(emp.contract.to).isAfter(view.$helpers.getMomentTZ())) {
                emp.active = true;
              }
            }
          }
        }

        // skip inactive emps if setting is active
        if (view.employeeSyncSetting && view.employeeSyncSetting.ignoreInactiveEmployees &&
            view.employeeSyncSettingsData && view.employeeSyncSettingsData.ignoreInactiveEmployees && !emp.active) {
          continue;
        }

        // check fields here
        let fieldsArray = view.checkFieldsForChanges(candidate, emp);
        if (fieldsArray.length === 0) {
          continue;
        }
        changedEmps.push({candidate: candidate, emp: emp, fieldsArray: fieldsArray});
      }

      return changedEmps;
    },
    changedEmployeeIds() {
      let changedEmpIds = [];
      this.changedEmployees.forEach(emp => {
        changedEmpIds.push(emp.emp.id);
      });
      return changedEmpIds;
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      console.log("REFRESH");
      let view = this;

      view.loading = true;
      view.jobs = [];
      view.filteredJobs = [];
      view.importJob = {
        id: null,
        method: null
      };
      view.externalImportCandidate = {};
      view.employees = [];
      view.candidates = [];
      view.inspectedCandidate = {};
      view.inspectedCandidates = [];

      view.employeeSyncSetting = undefined;
      view.employeeSyncSettingsData = {
        enableExportViaTransferService: false,
          enablePeriodicExport: false,
      };


      let promises = [];

      promises.push(view.loadEmployees());
      promises.push(view.loadEmployeeContracts());
      promises.push(view.$helpers
        .GetCompanySettings(force)
        .then((response) => {
          view.updateCompanySettings(response);
          return view.getEmployeeSyncSettings()
            .then((settings) => {
              view.employeeSyncSetting = settings.find(res => {
                return res.softwareId === view.companyData.softwareId
              });
              if (view.employeeSyncSetting !== undefined) {
                return view.getEmployeeSyncSettingsData()
                  .then((data) => {
                    view.employeeSyncSettingsData = data;
                    view.enableImport = true;
                  });
              } else {
                return Promise.resolve();
              }
            });
        }));

      Promise.all(promises).then(response => {
        view.loadEmployeeImportCandidates();
      })
      view.setupUIDataMaps();

      // load following data in the background
      view.loadFinancialAccountingGroups();
      view.$helpers.GetStoresSettings()
        .then(response => {
          response.data.forEach(store => {
            this.loadAllowedContracts(store.id);
          });
        });
    },
    updateCompanySettings: function (response) {
      let view = this;

      if (response.data.length === 1) {
        view.companyData = response.data[0];
      }
    },

    loadEmployees: function (force = false, employeeIdToSelect = null) {
      let view = this;
      return this.api
        .get("/sec/employee/coredata")
        .then((response) => {
          let empls = response.data.data;
          this.employees = empls;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadEmployeeContracts() {
      let view = this;
      let url = '/api/sec/proxy/md/employee/contracts';

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/proxy/md/employee/contracts');

      return this.axios({
        method: 'get',
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      }).then(response => {
        if (response.data !== undefined) {
          response.data.sort((a, b) => {
            if (a.from > b.from) {
              return 1;
            } else if (a.from < b.from) {
              return -1;
            }
            return 0;
          });
          // only save the latest contract per employee/store
          response.data.forEach(entry => {
            if (view.employeeContracts[entry.remoteId] === undefined) {
              view.employeeContracts[entry.remoteId] = [];
            }
            view.employeeContracts[entry.remoteId][entry.storeId] = entry;
          });
        }
      });
    },
    loadAllowedContracts(storeId) {
      let view = this;
      let url = '/api/sec/proxy/md/allowedContracts';
      view.allowedContracts = [];

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/proxy/md/allowedContracts');
      url = this.$helpers.appendParam("storeId", storeId, url, '/api/sec/proxy/md/allowedContracts');

      this.axios({
        method: 'get',
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      }).then(response => {
        if (response.data !== undefined) {
          view.allowedContracts[storeId] = [];
          response.data.forEach(allowedContract => {
            view.allowedContracts[storeId][allowedContract.id] = allowedContract;
          });
        }
      });
    },

    loadEmployeeImportCandidates: function (force = false) {
      let view = this;
      view.inspectedCandidates = [];

      console.log("loading candidates");
      let baseUrl = '/api/employee/candidate/latest';
      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("employeeImportJobMethod", view.software.toLowerCase(), url, baseUrl);

      this.axios({
        method: 'get',
        headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
        url: url,
      }).then(response => {
        view.candidates = response.data;
        // view.candidates.forEach(c => {
        //   console.log({"r": c.remoteId, "b": c.externalId, "l": c.lastName, "o": c});
        // });
        view.loading = false;

      }).catch(function (error) {
        console.log(error);
      })
    },
    loadAuthuser(uuid) {
      let view = this;
      if (!view.authusers.hasOwnProperty(uuid)) {
        view.authusers[uuid] = {
          id: uuid,
          uuid: uuid,
          name: "System",
          mail: uuid
        }
        let baseurl = '/api/sec/authuser';
        let url = baseurl;
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);
        url = this.$helpers.appendParam("uuid_text", uuid, url, baseurl);

        return this.axios({
          method: 'get',
          headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
          url: url,
        }).then(response => {
          view.authusers[uuid] = response.data.data;
        }).catch(function (error) {
          console.log(error);
        })
      } else {
        return Promise.resolve();
      }
    },

    forceEmployeeUpdateFromCandidate(candidate, emp) {
      let view = this;
      let payload = {
        candidateId: candidate.id,
        remoteId: candidate.remoteId
      };

      let url = "/sec/employee/candidate/update/force";
      this.api.post(url, payload).then((response) => {
        console.log(response);
        if (response.status == 200) {
          view.$helpers.success("Gespeichert", "Mitarbeiter erfolgreich auf den Stand des letzten Imports zurückgesetzt!");
          let idx = view.employees.indexOf(view.employees.find(employee => employee.id === emp.id));
          view.employees.splice(idx, 1);
        }
      }).catch((error) => {
        if (error.response.data.data && error.response.data.data.email) {
          view.$helpers.error("Fehler", "E-Mail Adresse " + error.response.data.data.email + " existiert bereits für einen anderen Mitarbeiter.");
        } else {
          view.$helpers.error("Fehler", "Mitarbeiter Update fehlgeschlagen.");
        }
      });
    },

    // trigger CSV export
    triggerCsvExport(remoteIds) {
      let view = this;
      view.loadingExport = true;

      let url = '/api/sec/employeesync/export';
      let baseurl = url;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);
      url = this.$helpers.appendParam("softwareId", view.companyData.softwareId, url, baseurl);
      if (remoteIds) {
        url = this.$helpers.appendParam("remoteIds", remoteIds, url, baseurl);
      }

      this.axios({
        method: "get",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((response) => {
        view.$helpers.info(
          "Info",
          "Export in das LV Tool wurde erfolgreich angestoßen."
        );
        view.closeDataChangedModal();
        view.loadingExport = false;
      }).catch((e) => {
        view.exporting = false;
        view.$helpers.error(
          "Fehler",
          "Fehler beim Erzeugen des Exports."
        );
        view.loadingExport = false;
      });
    },
    // CSV download
    downloadCSV(remoteIds = null) {
      let view = this;
      view.loadingDownload = true;

      let url = '/api/sec/employeesync/getexport';
      let baseurl = url;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);
      url = this.$helpers.appendParam("softwareId", view.companyData.softwareId, url, baseurl);
      if (remoteIds) {
        url = this.$helpers.appendParam("remoteIds", remoteIds, url, baseurl);
      }

      this.axios({
        method: "get",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((response) => {
        let csv = response.data.data;
        view.$helpers.forceFileDownload(view.$helpers.b64DecodeUnicode(csv), "Export.csv");
        view.closeDataChangedModal();
        view.loadingDownload = false;
      }).catch((e) => {
        view.$helpers.error(
          "Fehler",
          "Fehler beim Erzeugen des Exports."
        );
        view.loadingDownload = false;
      });
    },

    getEmployeeSyncSettings() {
      return this.$helpers.GetCall('/api/sec/employeesync/settings').then((response) => {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(response.data, false));
        });
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
    },
    getEmployeeSyncSettingsData() {
      return this.$helpers.GetCall('/api/sec/employeesync/data').then((response) => {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(response.data, false));
        });
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
    },

    GetProfilePictureUrl(employee, size = null, urlOnly = false) {
      let view = this;
      if (employee.employeePicture_fileId == null) {
        var port = location.port;
        var url =
          location.protocol +
          "//" +
          location.hostname +
          "/img/avatar.png";
        if (port != "80" && port != "443") {
          url =
            location.protocol +
            "//" +
            location.hostname +
            ":" +
            location.port +
            "/img/avatar.png";
        }

        if (urlOnly) return url;
        return "var(--contrast-1) no-repeat center/cover url(/img/avatar.png)";
      } else {
        var imgUrl =
          employee.employeePicture_server +
          "/api/file?id=" +
          employee.employeePicture_fileId +
          "&accessToken=" +
          employee.employeePicture_accessToken +
          "&s=" +
          this.imgCacheTimestamp;
        if (size) imgUrl = imgUrl + "&thumb=" + size;
        if (urlOnly) return imgUrl;
        return "var(--contrast-1) no-repeat center/cover  url(" + imgUrl + ")";
      }
    },
    formatDate(dateString) {
      if (dateString != null) {
        let d = new Date(dateString);
        return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear();
      }
      return null;
    },
    setupUIDataMaps() {
      this.uiDataMap.employeeCategories.forEach(item => this.employeeCategoryArray[item.id] = item.label_de);
      this.uiDataMap.employmentTypes.forEach(item => this.employmentTypeArray[item.id] = item.label_de);
      this.uiDataMap.contractTypes.forEach(item => this.contractTypeArray[item.id] = item.label_de);
      this.uiDataMap.wageTypes.forEach(item => this.wageTypeArray[item.id] = item.label_de);
    },
    checkFieldsForChanges(candidate, emp) {
      let view = this;
      let fieldsArray = [];

      if (!candidate || (candidate.firstName ?? "") != (emp.firstName ?? "")) {
        fieldsArray.push('firstName');
      }
      if (!candidate || (candidate.lastName ?? "") != (emp.lastName ?? "")) {
        fieldsArray.push('lastName');
      }
      if (!candidate || this.formatDate(candidate.dob) != this.formatDate(emp.dob)) {
        fieldsArray.push('dob');
      }
      if (!candidate || (candidate.socialsecurity ?? "") != (emp.socialsecurity ?? "")) {
        fieldsArray.push('socialsecurity');
      }
      let candidateGender = (candidate && view.genderCandidateArray.hasOwnProperty(candidate.gender)) ?
          view.genderArray[view.genderCandidateArray[candidate.gender]] :
          view.genderArray[0];
      if (!candidate || candidateGender != view.genderArray[emp.genderId]) {
        fieldsArray.push('gender');
      }
      if (!candidate || (candidate.email ?? "") != (emp.email ?? "")) {
        fieldsArray.push('email');
      }
      // if (!candidate || candidate.civilStatusId != emp.civilStatusId) {
      //   fieldsArray.push('civilStatusId');
      // }
      // if (!candidate || candidate.citizenTypeId != emp.citizenTypeId) {
      //   fieldsArray.push('citizenTypeId');
      // }
      if (!candidate || candidate.nationality != emp.citizenshipId) {
        fieldsArray.push('nationality');
      }
      if (!candidate || (candidate.address ?? "") != (emp.address ?? "")) {
        fieldsArray.push('address');
      }
      if (!candidate || (candidate.postalCode ?? "") != (emp.postalCode ?? "")) {
        fieldsArray.push('postalCode');
      }
      if (!candidate || (candidate.city ?? "") != (emp.city ?? "")) {
        fieldsArray.push('city');
      }
      if (!candidate || candidate.country != emp.countryId) {
        fieldsArray.push('country');
      }
      if (!candidate || (candidate.mobilephone ?? "") != (emp.mobilephone ?? "")) {
        fieldsArray.push('mobilephone');
      }
      if (!candidate || (candidate.homephone ?? "") != (emp.mobilephone ?? "")) {
        fieldsArray.push('homephone');
      }
      if (!candidate || (candidate.bankAccountHolder ?? "") != (emp.bankAccountHolder ?? "")) {
        fieldsArray.push('bankAccountHolder');
      }
      if (!candidate || (candidate.iban ?? "") != (emp.iban ?? "")) {
        fieldsArray.push('iban');
      }
      if (!candidate || (candidate.bic ?? "") != (emp.bic ?? "")) {
        fieldsArray.push('bic');
      }
      let fibuEmp = (emp.financialAccountingGroupId != null && view.financialAccountingGroups[emp.financialAccountingGroupId] != null)
              ? view.financialAccountingGroups[emp.financialAccountingGroupId].name : null;
      let fibuCandidate = (candidate && candidate.financialAccountingGroupNumber != null && view.fibuByNumber[candidate.financialAccountingGroupNumber] != null)
          ? view.fibuByNumber[candidate.financialAccountingGroupNumber].name : null;
      if (!candidate || fibuCandidate != fibuEmp) {
        fieldsArray.push('financialAccountingGroupId');
      }
      if (!candidate || (candidate.allocationGroup ?? "") != (emp.allocationGroup ?? "")) {
        fieldsArray.push('allocationGroup');
      }
      if (!candidate || (candidate.workEntryDate ?? "") != (emp.workEntryDate ?? "")) {
        fieldsArray.push('workEntryDate');
      }
      if (!candidate
          || (view.softwareIds[view.companyData.softwareId] === "BMD") && (candidate.externalId ?? "") != (emp.bmdId ?? "")
          || (view.softwareIds[view.companyData.softwareId] === "DPW") && (candidate.externalId ?? "") != (emp.dpwId ?? "")) {
        fieldsArray.push('externalId');
      }
      if (!candidate || emp.contract != null && (candidate.wageType ?? "") != (emp.contract.wageType ?? "")) {
        fieldsArray.push('wageType');
      }
      if (!candidate || emp.contract != null && (candidate.payment ?? "") != (emp.contract.payment ?? "")) {
        fieldsArray.push('payment');
      }
      // if (!candidate || emp.contract != null && view.allowedContracts[emp.contract.storeId] && view.allowedContracts[emp.contract.storeId][emp.contract.collectiveContractId]
      //     && candidate.collectiveContractId != view.allowedContracts[emp.contract.storeId][emp.contract.collectiveContractId].collectiveContractId) {
      //   fieldsArray.push('collectiveContractId');
      // }
      if (!candidate || emp.contract != null && (candidate.jobName ?? "") != (emp.contract.jobName ?? "")) {
        fieldsArray.push('jobName');
      }
      if (!candidate || emp.contract != null && this.contractTypeArray[candidate.contractType] != this.contractTypeArray[emp.contract.contractType]) {
        fieldsArray.push('contractType');
      }
      if (!candidate || emp.contract != null && this.formatDate(candidate.contractStart) != this.formatDate(emp.contract.from)) {
        fieldsArray.push('contractStart');
      }
      if (!candidate || emp.contract != null && this.formatDate(candidate.contractEnd) != this.formatDate(emp.contract.to)) {
        fieldsArray.push('contractEnd');
      }
      if (!candidate || emp.contract != null && this.employeeCategoryArray[candidate.employeeCategory] != this.employeeCategoryArray[emp.contract.employeeCategory]) {
        fieldsArray.push('employeeCategory');
      }
      if (!candidate || emp.contract != null && this.employmentTypeArray[candidate.employmentType] != this.employmentTypeArray[emp.contract.employmentType]) {
        fieldsArray.push('employmentType');
      }
      if (!candidate || emp.contract != null && (candidate.weekTimeRegular ?? "") != (emp.contract.weekTimeRegular ?? "")) {
        fieldsArray.push('weekTimeRegular');
      }
      // if (!candidate || emp.contract != null && view.allowedContracts[emp.contract.storeId] && view.allowedContracts[emp.contract.storeId][emp.contract.collectiveContractId]
      //     && candidate.monthTimeRegular != view.allowedContracts[emp.contract.storeId][emp.contract.collectiveContractId].monthTimeRegular) {
      //   fieldsArray.push('monthTimeRegular');
      // }
      if (!candidate || emp.contract != null && (candidate.weekWorkDaysRegular ?? "") != (emp.contract.weekWorkDaysRegular ?? "")) {
        fieldsArray.push('weekWorkDaysRegular');
      }
      return fieldsArray;
    },

    // fibu
    loadFinancialAccountingGroups: function(){
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      view.$helpers.GetCall('/api/financialaccountinggroups', params).then((response) => {
        let data = response.data.data;
        let tmp = {};
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            number: data[i].number,
            name: data[i].name,
          };
          tmp[data[i].id] = item;
        }
        view.financialAccountingGroups = tmp;
      }).catch((e) => {
        view.loading = false;
        console.log(e);
      });
    },

    openDataChangedModal(entry) {
      let view = this;
      console.log(entry);

      view.externalImportEmployee = entry.emp;
      let contract = entry.emp.contract;
      view.externalImportCandidate = entry.candidate ? entry.candidate : {};

      view.profilePictureUrl = view.GetProfilePictureUrl(view.externalImportEmployee, 'large', true);

      view.externalImportIssueFields = [];
      entry.fieldsArray.forEach(field => {
        switch (field) {
          //firstName
          case "firstName":
            view.externalImportIssueFields.push({
              field: "Vorname",
              employee: view.externalImportEmployee.firstName,
              candidate: view.externalImportCandidate.firstName
            });
            break;
          //lastName
          case "lastName":
            view.externalImportIssueFields.push({
              field: "Nachname",
              employee: view.externalImportEmployee.lastName,
              candidate: view.externalImportCandidate.lastName
            });
            break;
          //dob
          case "dob":
            view.externalImportIssueFields.push({
              field: "Geburtsdatum",
              employee: this.formatDate(view.externalImportEmployee.dob),
              candidate: this.formatDate(view.externalImportCandidate.dob)
            });
            break;
          //socialsecurity
          case "socialsecurity":
            view.externalImportIssueFields.push({
              field: "Sozialversicherungsnummer",
              employee: view.externalImportEmployee.socialsecurity,
              candidate: view.externalImportCandidate.socialsecurity
            });
            break;
          //gender
          case "gender":
            view.externalImportIssueFields.push({
              field: "Geschlecht",
              employee: view.genderArray[view.externalImportEmployee.genderId],
              candidate: Object.keys(view.externalImportCandidate).length === 0 ? "" : (view.genderCandidateArray.hasOwnProperty(view.externalImportCandidate.gender) ?
                view.genderArray[view.genderCandidateArray[view.externalImportCandidate.gender]] :
                view.genderArray[0])
            });
            break;
          //email
          case "email":
            view.externalImportIssueFields.push({
              field: "Email",
              employee: view.externalImportEmployee.email,
              candidate: view.externalImportCandidate.email
            });
            break;
          //civilStatusId
          // case "civilStatusId":
          //   view.externalImportIssueFields.push({
          //     field: "Familienstand",
          //     employee: view.civilStatusArray[view.externalImportEmployee.civilStatusId],
          //     candidate: view.civilStatusArray[view.externalImportCandidate.civilStatusId]
          //   });
          //   break;
          //citizenTypeId
          // case "citizenTypeId":
          //   view.externalImportIssueFields.push({
          //     field: "Bürgertyp",
          //     employee: view.citizenTypeArray[view.externalImportEmployee.citizenTypeId],
          //     candidate: view.citizenTypeArray[view.externalImportCandidate.citizenTypeId]
          //   });
          //   break;
          //nationality
          case "nationality":
            view.externalImportIssueFields.push({
              field: "Nationalität",
              employee: view.countryArray[view.externalImportEmployee.citizenshipId],
              candidate: view.countryArray[view.externalImportCandidate.nationality]
            });
            break;
          //address
          case "address":
            view.externalImportIssueFields.push({
              field: "Adresse",
              employee: view.externalImportEmployee.address,
              candidate: view.externalImportCandidate.address
            });
            break;
          //postalCode
          case "postalCode":
            view.externalImportIssueFields.push({
              field: "PLZ",
              employee: view.externalImportEmployee.postalCode,
              candidate: view.externalImportCandidate.postalCode
            });
            break;
          //city
          case "city":
            view.externalImportIssueFields.push({
              field: "Stadt",
              employee: view.externalImportEmployee.city,
              candidate: view.externalImportCandidate.city
            });
            break;
          //country
          case "country":
            view.externalImportIssueFields.push({
              field: "Land",
              employee: view.countryArray[view.externalImportEmployee.countryId],
              candidate: view.countryArray[view.externalImportCandidate.country]
            });
            break;
          //mobilephone
          case "mobilephone":
            view.externalImportIssueFields.push({
              field: "Mobiltelefon",
              employee: view.externalImportEmployee.mobilephone,
              candidate: view.externalImportCandidate.mobilephone
            });
            break;
          //homephone
          case "homephone":
            view.externalImportIssueFields.push({
              field: "Haustelefon",
              employee: view.externalImportEmployee.homephone,
              candidate: view.externalImportCandidate.homephone
            });
            break;
          //bankAccountHolder
          case "bankAccountHolder":
            view.externalImportIssueFields.push({
              field: "Kontoinhaber",
              employee: view.externalImportEmployee.bankAccountHolder,
              candidate: view.externalImportCandidate.bankAccountHolder
            });
            break;
          //iban
          case "iban":
            view.externalImportIssueFields.push({
              field: "IBAN",
              employee: view.externalImportEmployee.iban,
              candidate: view.externalImportCandidate.iban
            });
            break;
          //financialAccountingGroupId
          case "financialAccountingGroupId":
            view.externalImportIssueFields.push({
              field: "FIBU-Kreis",
              employee: (view.externalImportEmployee.financialAccountingGroupId != null && view.financialAccountingGroups[view.externalImportEmployee.financialAccountingGroupId] != null)
                ? view.financialAccountingGroups[view.externalImportEmployee.financialAccountingGroupId].name : null,
              candidate: (view.externalImportCandidate.financialAccountingGroupNumber != null && view.fibuByNumber[view.externalImportCandidate.financialAccountingGroupNumber] != null)
                ? view.fibuByNumber[view.externalImportCandidate.financialAccountingGroupNumber].name : null,
            });
            break;
          //allocationGroup
          case "allocationGroup":
            view.externalImportIssueFields.push({
              field: "Abrechnungskreis",
              employee: view.externalImportEmployee.allocationGroup,
              candidate: view.externalImportCandidate.allocationGroup
            });
            break;
          //bic
          case "bic":
            view.externalImportIssueFields.push({
              field: "BIC",
              employee: view.externalImportEmployee.bic,
              candidate: view.externalImportCandidate.bic
            });
            break;
          //workEntryDate
          case "workEntryDate":
            view.externalImportIssueFields.push({
              field: "Ersteintrittsdatum",
              employee: this.formatDate(view.externalImportEmployee.workEntryDate),
              candidate: this.formatDate(view.externalImportCandidate.workEntryDate)
            });
            break;
          //externalId
          case "externalId":
            view.externalImportIssueFields.push({
              field: "Externe ID",
              employee: view.softwareIds[view.companyData.softwareId] === "BMD" ? view.externalImportEmployee.bmdId
                  : (view.softwareIds[view.companyData.softwareId] === "DPW" ? view.externalImportEmployee.dpwId : ""),
              candidate: view.externalImportCandidate.externalId
            });
            break;
          //wageType
          case "wageType":
            view.externalImportIssueFields.push({
              field: "Lohntyp",
              employee: contract != null ? view.wageTypeArray[contract.wageType] : "",
              candidate: view.wageTypeArray[view.externalImportCandidate.wageType]
            });
            break;
          //payment
          case "payment":
            view.externalImportIssueFields.push({
              field: "Bezahlung",
              employee: contract != null ? contract.payment / 100.0 : "",
              candidate: view.externalImportCandidate.payment ? view.externalImportCandidate.payment / 100.0 : ""
            });
            break;
          //collectiveContractId
          // case "collectiveContractId":
          //   view.externalImportIssueFields.push({
          //     field: "Kollektivvertrag ID",
          //     employee: contract != null ? view.allowedContracts[contract.storeId][contract.collectiveContractId].collectiveContractId : "",
          //     candidate: view.externalImportCandidate.collectiveContractId
          //   });
          //   break;
          //jobName
          case "jobName":
            view.externalImportIssueFields.push({
              field: "Beruf",
              employee: contract != null ? contract.jobName : "",
              candidate: view.externalImportCandidate.jobName
            });
            break;
          //contractType
          case "contractType":
            view.externalImportIssueFields.push({
              field: "Vertragstyp",
              employee: contract != null ? this.contractTypeArray[contract.contractType] : "",
              candidate: this.contractTypeArray[view.externalImportCandidate.contractType]
            });
            break;
          //contractStart
          case "contractStart":
            view.externalImportIssueFields.push({
              field: "Vertragsbeginn",
              employee: contract != null ? this.formatDate(contract.from) : "",
              candidate: this.formatDate(view.externalImportCandidate.contractStart)
            });
            break;
          //contractEnd
          case "contractEnd":
            view.externalImportIssueFields.push({
              field: "Vertragsende",
              employee: contract != null ? this.formatDate(contract.to) : "",
              candidate: this.formatDate(view.externalImportCandidate.contractEnd)
            });
            break;
          //employeeCategory
          case "employeeCategory":
            view.externalImportIssueFields.push({
              field: "Mitarbeiter Kategorie",
              employee: contract != null ? this.employeeCategoryArray[contract.employeeCategory] : "",
              candidate: this.employeeCategoryArray[view.externalImportCandidate.employeeCategory]
            });
            break;
          //employmentType
          case "employmentType":
            view.externalImportIssueFields.push({
              field: "Anstellungsart",
              employee: contract != null ? this.employmentTypeArray[contract.employmentType] : "",
              candidate: this.employmentTypeArray[view.externalImportCandidate.employmentType]
            });
            break;
          //weekTimeRegular
          case "weekTimeRegular":
            view.externalImportIssueFields.push({
              field: "Wochenstunden",
              employee: contract != null ? contract.weekTimeRegular / 60.0 : "",
              candidate: view.externalImportCandidate.weekTimeRegular ? view.externalImportCandidate.weekTimeRegular / 60.0 : ""
            });
            break;
          //monthTimeRegular
          case "monthTimeRegular":
            view.externalImportIssueFields.push({
              field: "Monatsstunden",
              employee: contract != null ? (Math.round((view.allowedContracts[contract.storeId][contract.collectiveContractId].monthTimeRegular / 60.0 + Number.EPSILON) * 100) / 100) : "",
              candidate: view.externalImportCandidate.monthTimeRegular ? (Math.round((view.externalImportCandidate.monthTimeRegular / 60.0 + Number.EPSILON) * 100) / 100) : ""
            });
            break;
          //weekWorkDaysRegular
          case "weekWorkDaysRegular":
            view.externalImportIssueFields.push({
              field: "Arbeitstage pro Woche",
              employee: contract != null ? contract.weekWorkDaysRegular : "",
              candidate: view.externalImportCandidate.weekWorkDaysRegular
            });
            break;
          default: break;
        }


      });

      this.$refs["modal-data-changed"].show();
    },
    closeDataChangedModal() {
      this.$refs["modal-data-changed"].hide();
    },
  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>

.xColumn3 {
  width: 400px;
  text-align: left;
  float: left;
  margin-left: 25px;
  margin-right: 25px;
  overflow-y: auto;
  max-height: 800px;
}

.btn-ml {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}

tr:hover .btn-ml {
  color: #fff;
  background-color: var(--ml-alt);
  border-color: #fff;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.emp-core-data {
  display: flex;
  align-items: center;
  padding: 5px;
}

.emp-core-data > div {
  padding-right: 5px;
}


</style>
